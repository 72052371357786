import { DatePicker, Form, FormInstance, Input, Select } from 'antd';

import { gql } from '@apollo/client';
import {
  MilestoneFormEdit_MilestoneFragment,
  MilestoneForm_InitiativeTagFragment,
} from '../../../../../../../../generated/graphql';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { InitiativeTag } from '../../../../../../../../components/initiative/InitiativeTag';
import { AlignmentIcon } from '../../../../../../../../components/initiatives/InitiativeForm/Icons/AlignmentIcon';
import { EndingPointIcon } from '../../../../../../../../components/initiatives/InitiativeForm/Icons/EndingPointIcon';
import { PersonSearchIcon } from '../../../../../../../../components/initiatives/InitiativeForm/Icons/PersonSearchIcon';
import { TextAreaIconIcon } from '../../../../../../../../components/initiatives/InitiativeForm/Icons/TextAreaIcon';
import { TitleIcon } from '../../../../../../../../components/initiatives/InitiativeForm/Icons/TitleIcon';
import { StrategicPlanAdminSelector } from '../../../../../../../../components/initiatives/InitiativeForm/strategicPlanAdminSelector/StrategicPlanAdminSelector';
import {
  Icons,
  InitiativeIcon,
} from '../../../../../../initiatives_old/initiativesPageV1/InitiativeIcons';
import { Suspense } from 'react';
import { z } from 'zod';
import { zDayjs } from '../../../../../../../../services/zodCustomTypes';

interface Props {
  form: FormInstance<any>;
  initialValue?: MilestoneFormEdit_MilestoneFragment;
  tag?: MilestoneForm_InitiativeTagFragment;
  loading?: boolean;
}

export const MilestoneForm = ({ loading, form, tag, initialValue }: Props) => {
  const { t } = useTranslation();
  const initialValues = initialValue
    ? {
        name: initialValue.name,
        assignedTo: initialValue?.assignedTo[0].id,
        deadlineAt: dayjs(initialValue.deadlineAt),
        description: initialValue.description,
        rev: initialValue.rev,
      }
    : undefined;

  return (
    <Form
      disabled={loading}
      form={form}
      layout="vertical"
      requiredMark={'optional'}
      initialValues={initialValues}
      clearOnDestroy
    >
      <div className="flx">
        <Form.Item name="rev" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <TitleIcon className="mr MilestoneForm__formIcon--singleField" />
        <Form.Item
          name="name"
          className="flx--1"
          label={t('common.title')}
          rules={[
            {
              required: true,
              message: t('MilestoneForm.titleMandatoryError'),
            },
            {
              min: 3,
              max: 51,
              message: t('MilestoneForm.titleError'),
            },
          ]}
        >
          <Input
            type="text"
            className="bold"
            style={{ fontSize: '18px' }}
            placeholder={t('MilestoneForm.placeholder', {
              label: t('common.title'),
            })}
          />
        </Form.Item>
      </div>
      <div className="flx">
        <TextAreaIconIcon className="mr MilestoneForm__formIcon--singleField" />
        <Form.Item
          name="description"
          className="flx--1"
          label={t('common.initiative.purpose')}
          rules={[
            {
              required: true,
              message: t('MilestoneForm.purposeError'),
            },
            {
              min: 3,
              max: 500,
              message: t('MilestoneForm.purposeError'),
            },
          ]}
        >
          <TextArea
            rows={4}
            showCount
            maxLength={500}
            placeholder={t('MilestoneForm.placeholder', {
              label: t('common.initiative.purpose'),
            })}
          />
        </Form.Item>
      </div>
      <div className="flx">
        <PersonSearchIcon className="mr MilestoneForm__formIcon--singleField" />
        <Suspense
          fallback={
            <Form.Item
              className="flx--1 "
              label={t('MilestoneForm.ownerTitle')}
            >
              <Select loading placeholder="...loading owners" />
            </Form.Item>
          }
        >
          <Form.Item
            name="assignedTo"
            className="flx--1 "
            label={t('MilestoneForm.ownerTitle')}
            rules={[
              {
                required: true,
                message: t('MilestoneForm.ownerTitle'),
              },
            ]}
          >
            <StrategicPlanAdminSelector />
          </Form.Item>
        </Suspense>
      </div>
      <div className="flx">
        <AlignmentIcon className="mr MilestoneForm__formIcon--singleField" />
        <Form.Item
          className="flx--1 mr--m"
          label={t('MilestoneForm.alignment')}
          required
        >
          {/* required is set only to hide '(optional) label */}
          {tag && (
            <InitiativeTag
              title={tag.title}
              borderColor={tag.colorCode}
              icon={Icons[tag.iconId as InitiativeIcon]}
              // completed={}
              // archived={initialValue?.archived}
            />
          )}
        </Form.Item>
      </div>
      <div className="flx ">
        <EndingPointIcon className="mr MilestoneForm__formIcon--singleField" />
        <Form.Item
          className="flx--1"
          label={t('MilestoneForm.deadline')}
          name="deadlineAt"
          required
        >
          <DatePicker picker="date" className="fullWidth" />
        </Form.Item>
      </div>
    </Form>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_FORM__INITIATIVE_TAG = gql`
  fragment MilestoneForm_InitiativeTag on InitiativeTag {
    title
    iconId
    colorCode
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_FORM_EDIT__MILESTONE = gql`
  fragment MilestoneFormEdit_Milestone on Milestone {
    id
    name
    deadlineAt
    description
    rev
    assignedTo {
      id
      name
      displayName
      email
    }
  }
`;

const sharedSchema = z.object({
  name: z.string(),
  assignedTo: z.string(),
  description: z.string(),
  deadlineAt: zDayjs,
});

export const createMilestoneFormSchema = sharedSchema.extend({});

export const editInitiativeFormSchema = createMilestoneFormSchema.extend({
  rev: z.string(),
});
