import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { InitiativeHeaderSection__InitiativeDetailedReportResponseFragment } from '../../../../../generated/graphql';
import { DisplayName } from '../../../../../components/DisplayName';
import { InitiativeStatus } from '../../../../../components/initiative/InitiativeStatus';
import { InitiativeGraph } from './components/InitiativeGraph/InitiativeGraph';
import { InitiativeHeaderSectionSkeleton } from './InitiativeHeaderSection.skeleton';
import './InitiativeHeaderSection.less';
import { InitiativeValue } from '../../../strategicPlan/components/Gigantish/components/GigantishLegendInitiative/components/InitiativeValue';
import ClampText from '../../../../../components/ClampText';
type Props = {
  initiativeReport: InitiativeHeaderSection__InitiativeDetailedReportResponseFragment;
};

export const InitiativeHeaderSection = ({ initiativeReport }: Props) => {
  const { t } = useTranslation();
  const { initiative } = initiativeReport || {};
  const milestoneGraph = initiativeReport.accumulatedStats.milestones;

  const supportedMigs = initiative.metadata.supports.filter(
    (supported) => supported.__typename === 'SupportedMig'
  );

  return (
    <div className="InitiativeHeaderSection">
      <div className="flx--1 pr--xxl mr--xl pa--xl">
        <div className="mb--l">
          <h5 className="txt--secondary">
            {t('InitiativeHeaderSection.statusLabel')}
          </h5>
          <h3>
            <InitiativeStatus
              status={initiative.metadata.status}
              archived={initiative.metadata.archived}
            />
          </h3>
        </div>
        <h5 className="txt--secondary mb">
          {t('InitiativeHeaderSection.purposeAndObjectives')}
        </h5>
        <ClampText maxLines={9} key={initiative.id}>
          <div className="preserveLinebreaks">{initiative.description}</div>
          <ul className="mt">
            {initiative?.objectives.map((ob, index) => (
              <li key={index} className="mb--skipLast">
                {ob.text}
              </li>
            ))}
          </ul>
        </ClampText>
      </div>
      <div className="InitiativeHeaderSection__rightSection pa--xl">
        <div className="mb--xl">
          <h5 className="txt--secondary mb">
            {t('InitiativeHeaderSection.progress')}
          </h5>
          <InitiativeGraph height={100} periodData={milestoneGraph.graph} />
        </div>

        <div className="InitiativeHeaderSection__meta">
          <div>
            <h5 className="txt--secondary mb--xs">
              {t('InitiativeHeaderSection.accountable')}
            </h5>
            {initiative?.assignedTo.map((assignee) => (
              <div key={assignee.id}>
                <DisplayName user={assignee} />
              </div>
            ))}
          </div>
          <div>
            <h5 className="txt--secondary mb--xs">
              {t('InitiativeHeaderSection.financialValueLabel')}
            </h5>
            <div className="flx flx--jc-space-between">
              {t('common.profit')}
              <InitiativeValue value={initiative.profit} />
            </div>
          </div>
          <div>
            <h5 className="txt--secondary mb--xs">
              {t('InitiativeHeaderSection.supportsMig')}
            </h5>
            {supportedMigs.map((supported) => (
              <div key={supported.item.id}>{supported.item.name}</div>
            ))}

            {supportedMigs.length === 0 && <div>--</div>}
          </div>
          <div>
            <h5 className="txt--secondary mb--xs">
              {t('InitiativeHeaderSection.financialValueLabel')}
            </h5>
            <div className="flx flx--jc-space-between">
              {t('common.revenue')}
              <InitiativeValue value={initiative.revenue} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

InitiativeHeaderSection.Skeleton = InitiativeHeaderSectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_HEADER_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment InitiativeHeaderSection__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    accumulatedStats {
      subInitiatives {
        total
      }

      milestones {
        completed
        total
        graph {
          date
          completed
          total
        }
      }
    }
    initiative {
      domainId {
        itemId
        tenantId
      }
      name
      description
      assignedTo {
        id
        name
        email
        displayName
        initials
      }
      startAt
      endAt
      id
      objectives {
        text
        completed
      }
      tag {
        title
        iconId
        colorCode
      }
      metadata {
        status
        completedAt
        archived

        supports {
          ... on SupportedMig {
            ...InitiativeHeaderSection__SupportedMig
          }
        }
      }
      profit {
        potential
        current
      }
      revenue {
        potential
        current
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SUPPORTED_MIG_FRAGMENT = gql`
  fragment InitiativeHeaderSection__SupportedMig on SupportedMig {
    type
    item {
      id
      name
    }
  }
`;
