import { useTranslation } from 'react-i18next';

import { InitiativeGraph } from './components/InitiativeGraph/InitiativeGraph';
import Skeleton from 'react-loading-skeleton';

export const InitiativeHeaderSectionSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="InitiativeHeaderSection">
      <div className="flx--1 pr--xxl mr--xl pa--xl">
        <div className="mb--l">
          <h5 className="txt--secondary">
            {t('InitiativeHeaderSection.statusLabel')}
          </h5>
          <h3>
            <Skeleton />
          </h3>
        </div>
        <h5 className="txt--secondary mb">
          {t('InitiativeHeaderSection.purposeAndObjectives')}
        </h5>
        <Skeleton count={6.3} />
      </div>
      <div className="InitiativeHeaderSection__rightSection pa--xl">
        <div className="mb--xl">
          <h5 className="txt--secondary mb">
            {t('InitiativeHeaderSection.progress')}
          </h5>
          <InitiativeGraph
            height={100}
            periodData={[]}
            legendGoal={0}
            legendCompleted={0}
          />
        </div>

        <div className="InitiativeHeaderSection__meta">
          <div>
            <h5 className="txt--secondary mb--xs">
              {t('InitiativeHeaderSection.accountable')}
            </h5>
            <Skeleton />
          </div>
          <div>
            <h5 className="txt--secondary mb--xs">
              {t('InitiativeHeaderSection.financialValueLabel')}
            </h5>
            <Skeleton />
          </div>
          <div>
            <h5 className="txt--secondary mb--xs">
              {t('InitiativeHeaderSection.supportsMig')}
            </h5>
            <Skeleton />
          </div>
          <div>
            <h5 className="txt--secondary mb--xs">
              {t('InitiativeHeaderSection.financialValueLabel')}
            </h5>
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  );
};
