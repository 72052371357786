import { Colors } from '../../appPages/componentLibrary/Colors';
import { CheckIcon } from '../../icons/overviewIcons/CheckIcon';
import { UpcomingEventIcon } from '../../icons/overviewIcons/UpcomingEventIcon';
import { WarningIcon } from '../../icons/performanceIcons/WarningIcon';
import { ProgressStatsSkeleton } from './ProgressStats.Skeleton';

interface Props {
  stats: {
    completed: number;
    overdue: number;
    upcoming: number;
  };
}

export const ProgressStats = ({ stats }: Props) => {
  return (
    <div className="flx">
      <div className=" mr flx flx--ai-center ">
        <CheckIcon style={{ fontSize: 15, color: Colors.Status.OK_GREEN }} />
        <div className="font-size--lg txt--primary space--l">
          {stats.completed}
        </div>
      </div>
      <div className="mr flx flx--ai-center">
        <WarningIcon
          style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
          className="space--r"
        />
        <div className="font-size--lg txt--primary space--l">
          {stats.overdue}
        </div>
      </div>
      <div className="mr flx flx--ai-center txt--primary">
        <UpcomingEventIcon
          style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
          className="space--r"
        />
        <div className="font-size--lg txt--primary space--l">
          {stats.upcoming}
        </div>
      </div>
    </div>
  );
};

ProgressStats.Skeleton = ProgressStatsSkeleton;
