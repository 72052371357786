import { useMemo, useRef, useState } from 'react';
import './Gigantish.less';
import dayjs from 'dayjs';
import { GigantishLegendInitiative } from './components/GigantishLegendInitiative/GigantishLegendInitiative';
import { GigantishChartColumns } from './components/GigantishChartColumns';
import { GigantishChartInitiative } from './components/GigantishChartInitiative';
import { Btn } from '../../../../../components/Button';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import { useElementWidth } from './useElementWidth';
import { useTranslation } from 'react-i18next';
import {
  GigantishNode,
  gigantishTreeBuilder,
} from './components/GigantishTreeBuilder';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  DurationStatus,
  GetTopInitiativesForGigantishDocument,
} from '../../../../../generated/graphql';

type GigantishInitiative = {
  revenue: {
    potential?: number | null;
    current?: number | null;
  } | null;
  profit: {
    potential?: number | null;
    current?: number | null;
  } | null;
  startAt: string | null;
  endAt: string | null;
  metadata: {
    status: DurationStatus;
    completedAt?: string | null;
    archived?: boolean;
  };
};

export type GigantishTreeNode = GigantishNode<
  GigantishInitiative,
  GigantishInitiative
>;

interface DateBoundaries {
  minStartDate: string | null;
  maxEndDate: string | null;
}

function findDateBoundaries(nodes: GigantishTreeNode[]): DateBoundaries {
  let boundaries: DateBoundaries = {
    minStartDate: null,
    maxEndDate: null,
  };

  function traverse(currentNode: GigantishTreeNode) {
    const { data, children } = currentNode;

    if (data && data.initiative) {
      const { startAt, endAt } = data.initiative;
      if (startAt) {
        if (
          !boundaries.minStartDate ||
          dayjs(startAt).isBefore(boundaries.minStartDate)
        ) {
          boundaries.minStartDate = startAt;
        }
      }
      if (endAt) {
        if (
          !boundaries.maxEndDate ||
          dayjs(endAt).isAfter(boundaries.maxEndDate)
        ) {
          boundaries.maxEndDate = endAt;
        }
      }
    }

    if (children) {
      children.forEach((child) => traverse(child));
    }
  }

  nodes.forEach((node) => traverse(node));
  return boundaries;
}

export const Gigantish = () => {
  const { t } = useTranslation();

  const { data } = useSuspenseQuery(GetTopInitiativesForGigantishDocument, {
    variables: { filter: { companyInitiatives: true } },
  });

  const memoizedGigantishTree = useMemo(
    () => gigantishTreeBuilder(data.topInitiativeReport.initiatives),
    [data.topInitiativeReport.initiatives]
  );
  const [expandedInitiatives, setExpandedInitiatives] = useState<string[]>([]);

  const initiativesBoundaries = useMemo(
    () => findDateBoundaries(memoizedGigantishTree),
    [memoizedGigantishTree]
  );

  const earliestStartDate = dayjs(initiativesBoundaries.minStartDate); // from initiatives
  const latestEndDate = dayjs(
    initiativesBoundaries.maxEndDate ?? dayjs().add(10, 'years')
  ); // max initiatives end date OR today + 10 years

  const boundary = {
    start: earliestStartDate.subtract(1, 'year').startOf('year'),
    end: latestEndDate.add(5, 'year').endOf('year'),
  };

  const chartWindowRef = useRef<HTMLDivElement>(null);
  const currentChartWindowWidth = useElementWidth(chartWindowRef, {
    onExpand: (newWidth: number) => {
      const newDaysInWindow = pxToDays(newWidth);
      const newWindowEndDate = windowStartDate.add(newDaysInWindow, 'day');
      const canShowMoreDays = newWindowEndDate.isSameOrAfter(boundary.end);

      if (canShowMoreDays) {
        setWindowStartDate(boundary.end.subtract(newDaysInWindow, 'day'));
      }
    },
  });

  const [windowStartDate, setWindowStartDate] = useState(dayjs('2024-01-01'));
  const windowEndDate = windowStartDate.add(
    pxToDays(currentChartWindowWidth),
    'day'
  );

  const totalDaysWithinBoundary = boundary.end.diff(boundary.start, 'days');

  const handleToggle = (id: string) => {
    if (expandedInitiatives.includes(id)) {
      setExpandedInitiatives(expandedInitiatives.filter((i) => i !== id));
    } else {
      setExpandedInitiatives([...expandedInitiatives, id]);
    }
  };

  const windowOffset = daysToPx(
    boundary.start.diff(windowStartDate, 'days') + 1
  );
  const daysSinceLeftBoundary = dayjs().diff(boundary.start, 'days');

  return (
    <div className="Gigantish">
      <div className="Gigantish__legend">
        <div className="Gigantish__legendHeader">
          <div className="mr--auto">{t('Gigantish.companyInitiatives')}</div>
          <div className="Gigantish__valueColumn">{t('Gigantish.profit')}</div>
          <div className="Gigantish__valueColumn">{t('Gigantish.revenue')}</div>
        </div>
        <div className="Gigantish__legendRows">
          {memoizedGigantishTree.map((initiative) => (
            <GigantishLegendInitiative
              key={initiative.id}
              initiativeNode={initiative}
              expandedInitiatives={expandedInitiatives}
              onToggle={handleToggle}
            />
          ))}
        </div>
      </div>
      <div className="flx--1 relative">
        <Btn
          className="Gigantish__prevBtn"
          type="link"
          disabled={windowStartDate.isSameOrBefore(boundary.start)}
          icon={<CaretLeftFilled style={{ fontSize: ICON_SIZE }} />}
          onClick={() => {
            let newWindowStartDate = windowStartDate
              .subtract(MONTHS_TO_STEP, 'months')
              .startOf('month');

            if (newWindowStartDate.isSameOrBefore(boundary.start)) {
              newWindowStartDate = boundary.start;
            }
            setWindowStartDate(newWindowStartDate);
          }}
        />
        <Btn
          type="link"
          className="Gigantish__nextBtn"
          disabled={windowEndDate.isSameOrAfter(boundary.end)}
          icon={<CaretRightFilled style={{ fontSize: ICON_SIZE }} />}
          onClick={() => {
            let newWindowStartDate = windowStartDate
              .add(MONTHS_TO_STEP, 'months')
              .startOf('month');
            const daysInCurrentWindow = pxToDays(currentChartWindowWidth);
            const newWindowEndDate = newWindowStartDate.add(
              daysInCurrentWindow,
              'day'
            );

            if (newWindowEndDate.isSameOrAfter(boundary.end)) {
              newWindowStartDate = boundary.end.subtract(
                daysInCurrentWindow,
                'day'
              );
            }

            setWindowStartDate(newWindowStartDate);
          }}
        />
        <div className="Gigantish__chartWindow" ref={chartWindowRef}>
          <div
            className="Gigantish__chartContent"
            style={{ transform: `translateX(${windowOffset}px)` }}
          >
            <div className="Gigantish__chartColumns">
              <GigantishChartColumns boundary={boundary} />
            </div>
            <div
              className="Gigantish__rows"
              style={{ width: daysToPx(totalDaysWithinBoundary) }}
            >
              {memoizedGigantishTree.map((initiative) => {
                return (
                  <GigantishChartInitiative
                    key={initiative.id}
                    windowStart={windowStartDate}
                    initiative={initiative}
                    expandedInitiatives={expandedInitiatives}
                    boundary={boundary}
                  />
                );
              })}
            </div>
            <div
              className="Gigantish__todayLine mt--xxl"
              style={{ left: daysToPx(daysSinceLeftBoundary) }}
            >
              <div className="Gigantish__todayLineLabel">Today</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DAY_WIDTH = 1;
const ICON_SIZE = 16;

const MONTHS_TO_STEP = 6;

export const daysToPx = (days: number) => days * DAY_WIDTH;
export const pxToDays = (px: number) => px / DAY_WIDTH;

interface InitData {
  id: string;
  name: string;
  completed?: boolean;
  archived?: boolean;
  startAt: string;
  endAt?: string;
  revenue?: {
    potential?: number;
    current?: number;
  };
  profit?: {
    potential?: number;
    current?: number;
  };
  subInitiatives?: Array<InitData>;
}

export interface InitiativeNode extends InitData {
  subInitiatives?: Array<InitiativeNode>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GIGANTISH_TOP_INITIATIVE_REPORT = gql`
  fragment gigantish__TopInitiativeReport on TopInitiativeReport {
    id
    initiative {
      ...gigantish__Initiative2
    }
    allSubInitiatives {
      ...gigantish__InitiativeLight
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GIGANTISH__INITIATIVE = gql`
  fragment gigantish__Initiative2 on Initiative2 {
    domainId {
      itemId
      tenantId
    }
    startAt
    endAt
    name
    id
    profit {
      potential
      current
    }
    revenue {
      potential
      current
    }
    tag {
      ...gigantish__InitiativeTag
    }
    metadata {
      completedAt
      archived
      status
      supports {
        ... on SupportedInitiative {
          type
          item {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
        ... on SupportedMig {
          type
          item {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GIGANTISH__INITIATIVE_LIGHT = gql`
  fragment gigantish__InitiativeLight on InitiativeLight {
    id
    domainId {
      itemId
      tenantId
    }
    tag {
      ...gigantish__InitiativeTag
    }
    name
    metadata {
      archived
      completedAt
      status
    }
    startAt
    endAt
    profit {
      potential
      current
    }
    revenue {
      potential
      current
    }
    metadata {
      status
      supports {
        ... on SupportedInitiativeLight {
          type
          item {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
        ... on SupportedMigLight {
          type
          item {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GIGANTISH__INITIATIVE_TAG = gql`
  fragment gigantish__InitiativeTag on InitiativeTag {
    title
    iconId
    colorCode
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVES_FOR_GIGANTISH = gql`
  query getTopInitiativesForGigantish(
    $tenantId: ID
    $filter: TopInitiativeFilter
  ) {
    topInitiativeReport(tenantId: $tenantId, filter: $filter) {
      initiatives {
        id
        companyInitiative
        ...gigantish__TopInitiativeReport
      }
    }
  }
`;
