import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateInitiativeLinkToTenantDocument,
  GetTopInitiativesForManageCompanyInitiativesPageDocument,
  TenantLinkTypes,
} from '../../../../../generated/graphql';
import { Btn } from '../../../../Button';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { Alert } from 'antd';
import { CompanyInitiativeTable } from './CompanyInitiativeTable';
import { InitiativeSelect } from './InitiativeSelect';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { ErrorAlert } from '../../../../ErrorAlert';
import { ManageCompanyInitiativesSkeleton } from './ManageCompanyInitiatives.skeleton';
import { LinkOutlined } from '@ant-design/icons';

export const ManageCompanyInitiatives = () => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(
    GetTopInitiativesForManageCompanyInitiativesPageDocument,
    {
      fetchPolicy: 'cache-first',
    }
  );

  const companyInitiatives = data.topInitiativeReport.initiatives.filter(
    (tr) => tr.companyInitiative
  );
  const otherInitiatives = data?.topInitiativeReport.initiatives.filter(
    (tr) => !tr.companyInitiative
  );

  const [selectedInitiative, setSelectedInitiative] = useState<
    { domainId: { itemId: string; tenantId: string } } | undefined
  >(undefined);

  const [createInitiativeLinkToTenant, { loading: createLoader }] = useMutation(
    CreateInitiativeLinkToTenantDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: <strong>{t('ManageCompanyInitiatives.linkSuccess')}</strong>,
        });
        setSelectedInitiative(undefined);
      },
      onError: (error) => {
        showNotification('error', {
          message: <strong>{t('ManageCompanyInitiatives.linkFailed')}</strong>,
          description: <ErrorAlert error={error} />,
        });
      },
      refetchQueries: () => [
        {
          query: GetTopInitiativesForManageCompanyInitiativesPageDocument,
        },
      ],
    }
  );

  const handleSubmit = () => {
    if (selectedInitiative)
      createInitiativeLinkToTenant({
        variables: {
          domainId: {
            itemId: selectedInitiative.domainId.itemId,
            tenantId: selectedInitiative.domainId.tenantId,
          },
          type: TenantLinkTypes.INITIATIVE,
        },
      });
  };

  return (
    <div>
      <Alert
        className="mb--xl"
        showIcon
        closable
        message={t('ManageCompanyInitiatives.helpText')}
      />

      <h3 className="mb--l">{t('ManageCompanyInitiatives.linkInitiative')}</h3>
      <div className="flx mb--xl">
        <InitiativeSelect
          placeholder={t('ManageCompanyInitiatives.selectInitiative')}
          initiatives={otherInitiatives.map((tr) => tr.initiative)}
          onChange={setSelectedInitiative}
          className="flx--1"
        />
        <Btn
          type="primary"
          className="ml--xl"
          icon={<LinkOutlined />}
          onClick={handleSubmit}
          loading={createLoader}
          disabled={!selectedInitiative}
        >
          {t('ManageCompanyInitiatives.linkInitiative')}
        </Btn>
      </div>
      <h3 className="mb--l">{t('ManageCompanyInitiatives.currentlyLinked')}</h3>
      <CompanyInitiativeTable initiatives={companyInitiatives} />
    </div>
  );
};

ManageCompanyInitiatives.Skeleton = ManageCompanyInitiativesSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LINK_INITIATIVE_TO_TENANT = gql`
  mutation createInitiativeLinkToTenant(
    $tenantId: ID
    $domainId: TenantLinkDomainIdInput!
    $type: TenantLinkTypes!
  ) {
    createLinkToTenant(tenantId: $tenantId, domainId: $domainId, type: $type) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVES_MANAGE_COMPANY_INITIATIVES = gql`
  query getTopInitiativesForManageCompanyInitiativesPage($tenantId: ID) {
    topInitiativeReport(tenantId: $tenantId) {
      initiatives {
        id
        domainId {
          itemId
          tenantId
        }
        companyInitiative
        initiative {
          metadata {
            status
          }
          domainId {
            itemId
            tenantId
          }
          name
          id
          tag {
            title
            iconId
            colorCode
          }
          metadata {
            completedAt
            archived
            status
          }
        }

        ...initiativeTable__TopInitiativeReport
        ...companyInitiativeTable__TopInitiativeReport
      }
    }
  }
`;
