import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { MilestoneOrActiveToggleSkeleton } from './MilestoneOrActiveToggle.skeleton';

export enum MilestoneOrActiveToggleEnum {
  Milestones,
  Activities,
}

interface Props {
  onChange: (e: MilestoneOrActiveToggleEnum) => void;
  value: MilestoneOrActiveToggleEnum;
  disabled?: boolean;
  totalMilestones?: number;
  totalActivities?: number;
}

export const MilestoneOrActiveToggle = ({
  onChange,
  value,
  disabled = false,
  totalMilestones,
  totalActivities,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Radio.Group
      onChange={({ target: { value } }) => onChange(value)}
      value={value}
      disabled={disabled}
    >
      <Radio value={MilestoneOrActiveToggleEnum.Milestones}>
        {t('common.milestones')} {totalMilestones && `(${totalMilestones})`}
      </Radio>

      <Radio value={MilestoneOrActiveToggleEnum.Activities}>
        {t('common.activities')} {totalActivities && `(${totalActivities})`}
      </Radio>
    </Radio.Group>
  );
};

MilestoneOrActiveToggle.Skeleton = MilestoneOrActiveToggleSkeleton;
